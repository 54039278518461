
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { AuthMixin, BAIconsMixin, StringsMixin, VuetifyMixin, StatusMixin, StripeMixin, PurchaseMixin, AthleteRoutingMixin, AthleteProfileMixin, StylesMixin } from '@/mixins';
import { BAEventModel, EventTicketModel, BAPurchaseModel } from '@/models'
import { userStore } from '@/store';
import OnboardingDialog from '@/views/onboarding/OnboardingDialog.vue';
import { User } from '@auth0/auth0-spa-js';
import TooltipButton from '../ui/TooltipButton.vue';
import { AgreementFile } from '@/models/file/FileModel';

enum PurchaseStep {
    Prepare,
    Information,
    Agreements,
    Review,
    Purchase,
}
@Component({
    components: {
        OnboardingDialog,
    }
})
export default class BestAthletesEventPurchaseDialog extends Mixins(BAIconsMixin, StringsMixin, VuetifyMixin, StatusMixin, StylesMixin, AuthMixin, StripeMixin, PurchaseMixin, AthleteRoutingMixin, AthleteProfileMixin) {
    @Prop() event: BAEventModel;
    @Prop() ticket: EventTicketModel;
    @Prop({type: Boolean}) large;

    loginRequest: boolean = false;

    PurchaseStep = PurchaseStep;
    purchaseStep = PurchaseStep.Prepare;
    currentAgreement: number = -1;
    get HasCurrentAgreement(): boolean {
        return this.currentAgreement >= 0;
    }
    get CurrentAgreement(): AgreementFile {
        if( this.currentAgreement < 0 || this.currentAgreement >= this.event.agreements.length ) return undefined;
        return this.event.agreements[this.currentAgreement];
    }
    NextPurchaseStep(): PurchaseStep {
        switch( this.purchaseStep ) {
            case PurchaseStep.Prepare:
                return( this.purchaseStep = PurchaseStep.Information );
            case PurchaseStep.Information:
                if( this.IsEmptyArray(this.event.agreements) ) return( this.purchaseStep = PurchaseStep.Review );
                this.currentAgreement = 0;
                return( this.purchaseStep = PurchaseStep.Agreements );
            case PurchaseStep.Agreements:
                this.purchaseStep = PurchaseStep.Information;
                if( this.currentAgreement >= (this.event.agreements.length - 1) ) return( this.purchaseStep = PurchaseStep.Review );
                const nextAgreement = this.currentAgreement + 1;
                this.currentAgreement = -1;
                this.onScrollToTop();
                this.currentAgreement = nextAgreement;
                return( this.purchaseStep = PurchaseStep.Agreements );
            case PurchaseStep.Review:
                this.ProceedToPayment();
            case PurchaseStep.Purchase:
        }
    }
    PrevPurchaseStep(): PurchaseStep {
        switch( this.purchaseStep ) {
            case PurchaseStep.Prepare:
                this.onHidePurchaseDialog();
                return( this.purchaseStep = undefined );
            case PurchaseStep.Information:
                return( this.purchaseStep = PurchaseStep.Prepare );
            case PurchaseStep.Agreements:
                return( this.purchaseStep = PurchaseStep.Information );
            case PurchaseStep.Review:
                this.currentAgreement = 0;
                return( this.purchaseStep = PurchaseStep.Agreements );
            case PurchaseStep.Purchase:
            return( this.purchaseStep = PurchaseStep.Review );
        }
    }
    get IsAgreementIncomplete(): boolean {
        if( this.purchaseStep != PurchaseStep.Agreements ) return false;
        return !this.CurrentAgreement.signed;
    }
    onScrollToTop() {
        this.$nextTick(() => {
            const agreementCard = this.$refs.agreementCard as HTMLElement;
            agreementCard.scrollTop = agreementCard.scrollHeight;
        })
    }
    onScrollToBottom() {
        const agreementCard = this.$refs.agreementCard as HTMLElement;
        agreementCard.scroll({top: agreementCard.scrollHeight, behavior:'smooth'});
        agreementCard.scrollTo({top: agreementCard.scrollHeight, behavior:'smooth'});
        agreementCard.scrollTop = agreementCard.scrollHeight;
    }

    gotoEventWebsite() {
        if( this.IsEmpty(this.event?.url) ) return;

        window.open(this.event.url, "_blank");
    }

    onOrderRequest() {
        this.athleteProfile = userStore.AthleteProfile;
        if( this.IsNotEmpty(this.athleteProfile) ) {
            this.purchaseRequest = new BAPurchaseModel().load({
                parentId: this.AthleteId,
                productId: this.event.id,
                priceId: this.ticket.id,
                date: new Date(),
                data: this.PurchaseAthleteLabels(this.event.dataLabels, this.athleteProfile),
                requested: true,
                completed: false,
            })
        }
        this.onShowPurchaseDialog();
    }

    async ProceedToPayment() {
        try {
            const purchase = await this.savePurchaseRequest();

            if( this.ticket.IsFree ) {
                await this.completePurchaseRequest(purchase);
                this.reloadWindow();
            } else {
                // go to Stripe to purchase the ticket
                await this.buyEventTicket(this.event, this.ticket, this.AthleteId);
            }
        } catch(e) {
            this.setError(`Unable to process purchase. Contact Best Athletes for support:\n\n${e.message}`);
            this.waitForStripe = false;
        }
    }

	async onLoginRequest() {
        try{
            this.loginRequest = true;
            this.setStatus(`Log in using your Best Athletes account`);
            const success = await this.loginUsingPopup();

            if( success ) {
                this.setStatus(`User logged in. Retrieving user information...`);
                const user: User = await this.updateAfterLogin();
                if( this.IsNotEmpty(user) ) {
                    this.setStatus(`User Logged in successfully. Refreshing page...`);
                    this.reloadWindow();
                }
            } else {
                this.setError("User login cancelled");
                this.loginRequest = false;
            }
        } catch(e) {
            this.loginRequest = false;
            this.setError(e);
            this.gotoLogout();
        }
	}
}

