
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { AppHostnameMixin, BAIconsMixin, VuetifyMixin } from '@/mixins';
import { BAEventModel } from '@/models/baEvent';

@Component
export default class BAEventShare extends Mixins(VuetifyMixin, AppHostnameMixin, BAIconsMixin) {
    @Prop({ type: Boolean }) loading;
    @Prop({ required: true }) event: BAEventModel;
    @Prop({ type: Boolean }) icon;

    get IsLoading(): boolean { return this.loading }

    showShare: boolean = false;
    onShareEvent() {
        this.showShare = true;
    }
    onPreview() {
        this.gotoAthletePublicLink(this.event.ShareLink);
    }
    onClose() {
        this.showShare = false
    }
    get ShareURL(): string {
        return `${this.PublicAthleteLink}/${this.event.ShareLink}`;
    }

}
